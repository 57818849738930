import React from 'react';
import OverviewCard from './OverviewCard';
import OverviewTable from './OverviewTable';
import Header from '../HeaderComponent';
import axios from 'axios';
import { baseUrl } from "../../shared/baseUrl";
import { connect } from 'react-redux';
import { setShipImo, setCurrentShip, setShipOptions } from '../../redux/ActionCreators';

const mapStateToProps = state => {
    return {
        defaultShip: state.defaultShip,
        overview: state.overview,
        loginAuth: state.loginAuth,
        options: state.options
    }
}

const mapDispatchToProps = dispatch => ({
    setCurrentShip: (input) => { dispatch(setCurrentShip(input)) },
    setShipImo: (input) => { dispatch(setShipImo(input)) },
    setShipOptions: (input) => { dispatch(setShipOptions(input)) },
});

class Overview extends React.Component {
    constructor(props) {
        super(props);

        this.getShipInfo = this.getShipInfo.bind(this);
    }

    componentDidMount() {
        this.getShipInfo();
    }

    getShipInfo = () => {
        const loggedInUserId = localStorage.getItem("userid");
        const currentshipvalue = sessionStorage.getItem('currentshipvalue');
        const currentshiplabel = sessionStorage.getItem('currentshiplabel');
        let urls = baseUrl + process.env.REACT_APP_SHIP_INFO;

        console.log('Request URL:', urls);

        axios({
            method: 'get',
            url: urls,
            params: {
                id: this.props.loginAuth.user !== null ? this.props.loginAuth.user['id'] : loggedInUserId
            }
        })
        .then((res) => {
            console.log('Response to request URL:', res.data);
            this.props.setShipOptions(res.data['Result']);
            console.log('Current ship value from sessionStorage:', currentshipvalue);
            console.log('Current ship label from sessionStorage:', currentshiplabel);

            if (currentshiplabel && currentshipvalue) {
                this.props.setShipImo(currentshipvalue);
                this.props.setCurrentShip({ 'label': currentshiplabel, 'value': currentshipvalue });
            } else {
                const firstShip = res.data['Result'][0];
                this.props.setShipImo(firstShip['value']);
                this.props.setCurrentShip(firstShip);
                sessionStorage.setItem('currentshipvalue', firstShip['value']);
                sessionStorage.setItem('currentshiplabel', firstShip['label']);
            }
        })
        .catch((error) => {
            console.log('Error catching ship_info:', error);
        });
    }

    render() {
        return (
            <div>
                <Header isOverview={true} />
                <OverviewCard />
                <OverviewTable />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview);


