/* FOR USER LOGIN */
export const IS_USER_LOGGED_IN = 'IS_USER_LOGGED_IN';
export const SET_USER = 'SET_USER';
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export const SET_USERNAME = 'SET_USERNAME'; //Will also be used in CHANGE PASSWORD COMPONENT
export const SET_PASSWORD = 'SET_PASSWORD';

/* FOR CHANGE PASSWORD */
export const SET_CHANGE_PASSWORD = 'SET_CHANGE_PASSWORD';
export const SET_CHANGE_CONFIRM_PASSWORD = 'SET_CHANGE_CONFIRM_PASSWORD';
export const SET_CHANGE_PASSWORD_ERROR = 'SET_CHANGE_PASSWORD_ERROR';
export const SET_CHANGE_PASSWORD_LOADING = 'SET_CHANGE_PASSWORD_LOADING';
export const SET_CHANGE_PASSWORD_RESPONSE = 'SET_CHANGE_PASSWORD_RESPONSE';


/* DEFAULT SHIP - USED TO SET THE REACT SELECT DROPDOWN WHEN A SHIP IS SELECTED TO BE BROWSED FROM THE OVERVIEW PAGE. */
export const SET_DEFAULT_SHIP = 'SET_DEFAULT_SHIP';

/* CURRENT SHIP - SET WHICH SHIP CURRENTLY SELECTED */
export const SET_CURRENT_SHIP = 'SET_CURRENT_SHIP';

/* USED TO SET THE TYPE OF DAILY INPUT - NOON or LOGS */
export const SET_DAILY_INPUT = 'SET_DAILY_INPUT';
export const SET_DAILY_INPUT_TYPE = 'SET_DAILY_INPUT_TYPE';

/* FOR SPREADSHEET COMPONENT */
export const SET_SPREADSHEET_DATA = 'SET_SPREADSHEET_DATA';
export const SET_SPREADSHEET_METADATA = 'SET_SPREADSHEET_METADATA';
export const SET_SPREADSHEET_LOADING = 'SET_SPREADSHEET_LOADING';
export const SET_SPREADSHEET_ERROR = 'SET_SPREADSHEET_ERROR';
export const SET_SPREADSHEET_NAMES = 'SET_SPREADSHEET_NAMES';

export const SET_TEMPLATE_LOADING = 'SET_TEMPLATE_LOADING';
export const SET_TEMPLATE_ERROR = 'SET_TEMPLATE_ERROR';
export const SET_TEMPLATE_RESPONSE = 'SET_TEMPLATE_RESPONSE';

export const SET_SEND_BACKEND_LOADING = 'SET_SEND_BACKEND_LOADING';
export const SET_SEND_BACKEND_ERROR = 'SET_SEND_BACKEND_ERROR';
export const SET_SEND_BACKEND_RESPONSE = 'SET_SEND_BACKEND_RESPONSE';

/* FOR COLLAPSIBLE COMPONENT IN THE DAILY REPORT */
export const SET_COLLAPSIBLE_CATEGORY = 'SET_COLLAPSIBLE_CATEGORY';

/* FOR THE OPTIONS IN THE REACT SELECT COMPONENT*/
export const SET_SISTER_VESSELS = 'SET_SISTER_VESSELS';
export const SET_SIMILAR_VESSELS = 'SET_SIMILAR_VESSELS';
export const SET_INDEPENDENT_PARAMETERS = 'SET_INDEPENDENT_PARAMETERS';
export const SET_SLIDER_1 = 'SET_SLIDER_1';
export const SET_SLIDER_2 = 'SET_SLIDER_2';
export const SET_SLIDER_3 = 'SET_SLIDER_3';
export const SET_SLIDER_4 = 'SET_SLIDER_4';
export const SET_SLIDER_5 = 'SET_SLIDER_5';
export const SET_SLIDER_6 = 'SET_SLIDER_6';
export const SET_DEPENDENT_PARAMETERS = 'SET_DEPENDENT_PARAMETERS';
export const SET_DEPENDENT_PARAMETERS_FOR_Z_AXIS = 'SET_DEPENDENT_PARAMETERS_FOR_Z_AXIS';
export const SET_SHIP_OPTIONS = 'SET_SHIP_OPTIONS';
export const SET_EVALUATION_PERIOD_OPTIONS = 'SET_EVALUATION_PERIOD_OPTIONS';
export const SET_DRY_DOCK_PERIOD_OPTIONS = 'SET_DRY_DOCK_PERIOD_OPTIONS';


/* ACTION TYPES FOR TRENDS */
export const GET_TRENDS = 'TRENDS_DATA';
export const TRENDS_LOADING = 'TRENDS_LOADING';
export const TRENDS_FAILED = 'TRENDS_FAILED';
// export const SET_TRENDS = 'SET_TRENDS';
export const SET_DROPDOWN = 'SET_DROPDOWN';
export const SET_COMPARE = 'SET_COMPARE'; //COMMON WITH INTERACTIVE
export const SET_DURATION = 'SET_DURATION'; //COMMON WITH INTERACTIVE
export const SET_OUTLIERS = 'SET_OUTLIERS';
export const SET_ANOMALIES = 'SET_ANOMALIES';
export const SET_MULTI = 'SET_MULTI';
export const SET_EQUIPMENT_MULTI = 'SET_EQUIPMENT_MULTI';
export const SET_INDEX_MULTI = 'SET_INDEX_MULTI';
export const SET_SHIP_IMO = 'SET_SHIP_IMO';
export const SET_INDIVIDUAL_PARAMS = 'SET_INDIVIDUAL_PARAMS';
export const SET_INDIVIDUAL_EQUIP = 'SET_INDIVIDUAL_EQUIP';
export const SET_INDIVIDUAL_INDEX = 'SET_INDIVIDUAL_INDEX';
export const SET_GENERIC_GROUPS = 'SET_GENERIC_GROUPS';
export const SET_NOON_OR_LOGS = 'SET_NOON_OR_LOGS';
export const SET_DRY_DOCK_PERIOD = 'SET_DRY_DOCK_PERIOD';
export const SET_EVALUATION_PERIOD = 'SET_EVALUATION_PERIOD';
export const SET_PERFORMANCE_TYPE = 'SET_PERFORMANCE_TYPE';
export const SET_GROUP_SELECTION = 'SET_GROUP_SELECTION';
export const SET_DRY_DOCK_OPTION_SELECTION = 'SET_DRY_DOCK_OPTION_SELECTION';
export const SET_EVALUATION_OPTION_SELECTION = 'SET_EVALUATION_OPTION_SELECTION';

/* SET HOVER DATA */
export const SET_HOVERDATA = 'SET_HOVERDATA';
export const GET_HOVERDATA = 'GET_HOVERDATA';
export const SET_OUTLIER_HOVER = 'SET_OUTLIER_HOVER';
export const SET_ML_HOVER = 'SET_ML_HOVER';
export const SET_INTERACTIVE_HOVER = 'SET_INTERACTIVE_HOVER';
export const SET_MESSAGES_HOVER = 'SET_MESSAGES_HOVER';
export const SET_OUTLIER_MESSAGES = 'SET_OUTLIER_MESSAGES';

export const SET_HOVERDATA_FOR_FUEL_CONS = 'SET_HOVERDATA_FOR_FUEL_CONS';
export const GET_HOVERDATA_FOR_FUEL_CONS = 'GET_HOVERDATA_FOR_FUEL_CONS';

export const GET_NAMES_OF_OUTLIER_HOVER = 'GET_NAMES_OF_OUTLIER_HOVER';

export const SET_WEATHER_ON_HOVER = 'SET_WEATHER_ON_HOVER';

export const SET_DRY_DOCK_HOVER = 'SET_DRY_DOCK_HOVER';

/* ACTION TYPES FOR DAILY REPORT */
export const SET_DAILY_DATA = 'SET_DAILY_DATA';
export const DAILY_DATA_ERROR = 'DAILY_DATA_ERROR';
export const DAILY_DATA_LOADING = 'DAILY_DATA_LOADING';

/* ACTION TYPES FOR DAILY ISSUES */
export const SET_DAILY_ISSUES = 'SET_DAILY_ISSUES';
export const DAILY_ISSUES_ERROR = 'DAILY_ISSUES_ERROR';
export const DAILY_ISSUES_LOADING = 'DAILY_ISSUES_LOADING';

/* ACTION TYPES FOR INTERACTIVE */
export const SET_X_AXIS = 'SET_X_AXIS';
export const SET_Y_AXIS = 'SET_Y_AXIS';
export const SET_Z_AXIS = 'SET_Z_AXIS';
export const SET_COLOR = 'SET_COLOR';
export const SET_SIZE = 'SET_SIZE';
export const SET_SHAPE = 'SET_SHAPE';
export const SET_DIMENSIONS = 'SET_DIMENSIONS';
export const SET_COMBOS = 'SET_COMBOS';
export const SET_BASIC = 'SET_BASIC';
export const SET_MULTIPARAMETRIC = 'SET_MULTIPARAMETRIC';
export const SET_FEATURE_1 = 'SET_FEATURE_1';
export const SET_FEATURE_2 = 'SET_FEATURE_2';
export const SET_FEATURE_3 = 'SET_FEATURE_3';
export const SET_FEATURE_4 = 'SET_FEATURE_4';
export const SET_FEATURE_5 = 'SET_FEATURE_5';
export const SET_FEATURE_6 = 'SET_FEATURE_6';
export const SET_FEATURE_INPUT_1 = 'SET_FEATURE_INPUT_1';
export const SET_FEATURE_INPUT_2 = 'SET_FEATURE_INPUT_2';
export const SET_FEATURE_INPUT_3 = 'SET_FEATURE_INPUT_3';
export const SET_FEATURE_INPUT_4 = 'SET_FEATURE_INPUT_4';
export const SET_FEATURE_INPUT_5 = 'SET_FEATURE_INPUT_5';
export const SET_FEATURE_INPUT_6 = 'SET_FEATURE_INPUT_6';
export const SET_WIND_DIRECTION = 'SET_WIND_DIRECTION';
export const SET_SWELL_DIRECTION = 'SET_SWELL_DIRECTION';
export const SET_BALLAST_OR_LOADED = 'SET_BALLAST_OR_LOADED';
export const SET_INTERACTIVE_DATA = 'SET_INTERACTIVE_DATA';
export const SET_INTERACTIVE_STATS_DATA = 'SET_INTERACTIVE_STATS_DATA';
export const INTERACTIVE_LOADING = 'INTERACTIVE_LOADING';
export const INTERACTIVE_ERROR = 'INTERACTIVE_ERROR';
export const INTERACTIVE_TYPE_OF_INPUT = 'INTERACTIVE_TYPE_OF_INPUT';
export const SET_INTERACTIVE_COMPARE = 'SET_INTERACTIVE_COMPARE';
export const SET_INTERACTIVE_DURATION = 'SET_INTERACTIVE_DURATION';

/* ACTION TYPES FOR OVERVIEW */
export const SET_OVERVIEW_DATA = 'SET_OVERVIEW_DATA';
export const SET_OVERVIEW_LOADING = 'SET_OVERVIEW_LOADING';
export const SET_OVERVIEW_ERROR = 'SET_OVERVIEW_ERROR';

/* ACTION TYPES FOR AIS DATA */
export const SET_AIS_DATA = 'SET_AIS_DATA';
export const SET_AIS_LOADING = 'SET_AIS_LOADING';
export const SET_AIS_ERROR = 'SET_AIS_ERROR';

/* ACTION TYPES FOR CREATE NEW INTERVENTION */
export const SET_FROM_DATE = 'SET_FROM_DATE';
export const SET_TO_DATE = 'SET_TO_DATE';
export const SET_INTERVENTION_CATEGORY = 'SET_INTERVENTION_CATEGORY';
export const SET_CATEGORY_CODE = 'SET_CATEGORY_CODE';
export const SET_CATEGORY_DESCRIPTION = 'SET_CATEGORY_DESCRIPTION';
export const SET_INTERVENTION_TYPE = 'SET_INTERVENTION_TYPE';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_JOB_LIST = 'SET_JOB_LIST';
export const SET_EQUIPMENT_LIST = 'SET_EQUIPMENT_LIST';

/* ACTION TYPES FOR REPORTS */
export const SET_REPORT_TYPE = 'SET_REPORT_TYPE';
export const SET_DRY_DOCK_PERIOD_FOR_REPORTS = 'SET_DRY_DOCK_PERIOD_FOR_REPORTS';
export const SET_EVALUATION_PERIOD_FOR_REPORTS = 'SET_EVALUATION_PERIOD_FOR_REPORTS';
export const SET_OPEN_NEW_WINDOW = 'SET_OPEN_NEW_WINDOW';
export const SET_VOYAGE = 'SET_VOYAGE';
export const SET_VOYAGE_OPTIONS = 'SET_VOYAGE_OPTIONS';
export const SET_VOYAGE_PERFORMANCE_REPORT = 'SET_VOYAGE_PERFORMANCE_REPORT';
export const SET_REPORTS_LOADING = 'SET_REPORTS_LOADING';
export const SET_REPORTS_ERROR = 'SET_REPORTS_ERROR';
export const SET_SELECTED_PERIOD_FROM_DATE = 'SET_SELECTED_PERIOD_FROM_DATE';
export const SET_SELECTED_PERIOD_TO_DATE = 'SET_SELECTED_PERIOD_TO_DATE';
export const SET_SELECTED_PERIOD_DATA = 'SET_SELECTED_PERIOD_DATA';
export const SET_AVAILABLE_DATES = 'SET_AVAILABLE_DATES';
export const SET_OUTLIER_DATES = 'SET_OUTLIER_DATES';
export const SET_OPERATIONAL_DATES = 'SET_OPERATIONAL_DATES';
export const SET_SPE_DATES = 'SET_SPE_DATES';

/* ACTION TYPES FOR PLOTLY GRAPH URLS */
export const SET_ACTUAL_BASELINE_FOC_URL_BALLAST_STRING = 'SET_ACTUAL_BASELINE_FOC_URL_BALLAST_STRING';
export const SET_ACTUAL_BASELINE_FOC_URL_LOADED_STRING = 'SET_ACTUAL_BASELINE_FOC_URL_LOADED_STRING';
export const SET_ACTUAL_BASELINE_FOC_SPEED_URL_BALLAST_STRING = 'SET_ACTUAL_BASELINE_FOC_SPEED_URL_BALLAST_STRING';
export const SET_ACTUAL_BASELINE_FOC_SPEED_URL_LOADED_STRING = 'SET_ACTUAL_BASELINE_FOC_SPEED_URL_LOADED_STRING';
export const SET_PERCENTAGE_DEVIATION_URL_BALLAST_STRING = 'SET_PERCENTAGE_DEVIATION_URL_BALLAST_STRING';
export const SET_PERCENTAGE_DEVIATION_URL_LOADED_STRING = 'SET_PERCENTAGE_DEVIATION_URL_LOADED_STRING';

/* ACTION TYPES FOR PERFORMANCE */
export const SET_BASELINE_ACTUAL_FOC = 'SET_BASELINE_ACTUAL_FOC';
export const SET_BASELINE_ACTUAL_FOC_SPEED = 'SET_BASELINE_ACTUAL_FOC_SPEED';
export const SET_PERFORMANCE_DEVIATION = 'SET_PERFORMANCE_DEVIATION';
export const SET_PERFORMANCE_TABLE = 'SET_PERFORMANCE_TABLE';